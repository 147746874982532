<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ editData ? 'Edit' : 'Add' }} BDO</h1>
                            <button 
                                @click="closeModal" 
                                type="button" 
                                class="btn-close" 
                                data-bs-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="modal-body">

                    <div class="pb-2">

                        <div class="row">

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Select contact profile</label>
                                <AsyncSelect 
                                    placeholder="Select contact profile"
                                    v-model="formData.contact_profile_id"
                                    :api-service="fetchContactProfiles"
                                    :additional-query="{ type: 'sales_representative'}"
                                    :reduce="option => option.id"
                                    :additional-option="additionalOption"
                                    :format-label="formatPatientLabel"
                                    label="name"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Select User</label>
                                <AsyncSelect
                                    placeholder="Select User"
                                    v-model="formData.bdo_user_id"
                                    :api-service="fetchContacts"
                                    :reduce="option => option.id"
                                    :additional-option="additionalUserOption"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Select department</label>
                                <div class="input-group input-group-merge w-100">
                                    <v-select 
                                        placeholder="Select department" class="w-100" 
                                        :options="departments" label="name"
                                        :reduce="(name) => name.id" 
                                        v-model="formData.department_id"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Start date</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.start_date"
                                        name="datetime"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">End date</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.end_date"
                                        name="datetime"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Status</label>
                                <v-select
                                    placeholder="Select status"
                                    :options="noteStatus" v-model="formData.status"
                                    :reduce="name => name.value"
                                    label="name"
                                />
                            </div>

                            <div class="col-12 text-center">

                                <hr class="mt-2">

                                <button 
                                    :disabled="loading" @click="submit"
                                    class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
                                >
                                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    Submit
                                </button>

                                <button @click="closeModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import { useAsyncDropdownHelper } from "@/services/utils/asyncDropdownHelper";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import { getCurrentDateTime } from '@/services/utils/global.js'
import { inject, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import handleContact from '@/services/modules/contact';
import handleCrm from '@/services/modules/crm/crm'

const props = defineProps({
    editData: Object | null,
    onSubmit: Function,
    departments: Array,
    noteStatus: Array
})

const emit = defineEmits(['onSubmit']);

const { fetchContactProfiles } = handleContact();
const { fetchAreaList, storeSR, updateSr } = handleCrm();
const { formatPatientLabel } = useAsyncDropdownHelper()
const { fetchContacts } = handleImportTracker();

const store = useStore();
const route = useRoute();

const service_product = ref(null);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const additionalOption = ref([]);
const additionalUserOption = ref([]);

const formData = reactive({
    company_id: null,
    contact_profile_id: null,
    bdo_user_id: null,
    area_id: null,
    department_id: null,
    start_date: getCurrentDateTime(),
    end_date: null,
    status: 'active'
});

const closeModal = () => {
    store.state.isModalOpen = false;
}

const submit = async () => {

    formData.company_id = route.params.companyId;
    const id = props.editData ? props.editData.id : null;
    const srAction = id ? updateSr : storeSR;

    loading.value = true

    await srAction(formData, id).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        closeModal();
        emit('onSubmit');
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const setEditData = (data) => {
    formData.contact_profile_id = data.contact_profile_id;
    formData.area_id = data.area_id;
    formData.bdo_user_id = data.bdo_user_id;
    formData.department_id = data.department_id;
    formData.start_date = data.start_date;
    formData.end_date = data.end_date;
    formData.status = data.status

    additionalOption.value.push({
        id: data.contact_profile_id,
        name: data.contact_profile.full_name
    })

    if(data.bdo_user_id){
        additionalUserOption.value.push({
            id: data.bdo_user_id,
            name: data.bdo_user_name
        })
    }
    
}

onMounted(() => {
    if(props.editData){
        setEditData(props.editData)
    }
})

</script>


<style scoped>
.med-title {
    padding: 0.486rem 1.5rem;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>