<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="true"
                title="BDO List"
                btnTitle="Add new"
                @onClickAddNewButton="onClickAddNew"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >

            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable
                :tableItems="tableItems"
                @onEditSR="onEditSR"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

        <AddOrEditSRModal
            v-if="store.state.isModalOpen"
            :departments="departments"
            :noteStatus="noteStatus"
            :editData="editData"
            @onSubmit="onSubmit"
        />

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import AddOrEditSRModal from '@/components/molecule/company/crm/sr/AddOrEditSRModal.vue';
import ListTable from '@/components/molecule/company/crm/sr/SRTable.vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleHospital from '@/services/modules/hospital'
import handleCrm from '@/services/modules/crm/crm.js'

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const params = reactive({
    searchTxt: '',
    offset: 10
});
const departments = ref([]);
const editData = ref(null);
const noteStatus = reactive([
    {
        value: 'active',
        name: 'Active',
    },
    {
        value: 'inactive',
        name: 'Inactive'
    }
])

const { fetchDepartList } = handleHospital();
const { fetchSrList, fetchSingleSr } = handleCrm();

const onClickAddNew = () => {
    editData.value = null;
    store.state.isModalOpen = true;
}

const onClickGo = () => {
    
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const onSubmit = () => {
    getSRList();
}

const onEditSR = async (item) => {
    const query = getQuery();
    await fetchSingleSr(query, item.id).then((res) => {

        if(!res.status) return showError(res.message);

        editData.value = res.data;
        store.state.isModalOpen = true;
    })
}

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

async function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    await getSRList();
}

const getSRList = async () => {
    const query = getQuery();
    loading.value = true

    await fetchSrList(query).then(res => {
        loading.value = false
        if (!res.status){
            store.commit("resetPagination")
            return
        }
        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getDepartmentList = async () => {
    let query = `?company_id=${route.params.companyId}`;
    await fetchDepartList(query).then((res) => {
        if(!res.status) return;
        departments.value = res.data
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&search_txt=${params.searchTxt}`;
    query += '&offset=' + params.offset
    return query;
}

onMounted(async () => {
    await Promise.all([
        getSRList(),
        getDepartmentList()
    ]);
})
</script>

<style scoped>

</style>